<template>
    <div>
        <!-- 顶部搜索栏 -->
        <el-row>
            <div class="pad-container flx-row ali-c">
                <el-input placeholder="请输入活动名称" class="search-input" v-model="searchObj.goodsName"></el-input>
                <el-select placeholder="请选择活动状态" class="search-input ml-15" v-model="searchObj.goodsStatus">
                    <el-option
                            v-for="(item,index) in goodsStatusOptions"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                    ></el-option>
                </el-select>
                <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
                <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
            </div>
        </el-row>
        <!-- 表格内容 -->
        <el-row class="mt-24">
            <el-col el-col :xs="24" :sm="24" :lg="24">
                <div class="pad-container">
                    <el-button type="primary" class="mb-10" @click="handleAdd">
                        <i class="el-icon-plus mr-10"></i>添加活动
                    </el-button>
                    <el-tabs v-model="activePart" @tab-click="changeTab" v-if="!storeFlag">
                        <el-tab-pane
                                :label="item.storeName"
                                :name="item.id+''"
                                v-for="(item,index) in shopList"
                                :key="index"
                        ></el-tab-pane>
                    </el-tabs>
                    <div>
                        <!-- 表格 -->
                        <el-table class="mt-24 member-table" :data="tableData">
                            <el-table-column label="序号" width="100">
                                <template slot-scope="scope">{{scope.$index+1+(pageNum-1)*pageSize}}</template>
                            </el-table-column>
                            <el-table-column label="活动名称" prop="activityName"></el-table-column>
                            <el-table-column label="所属门店" prop="storeName"></el-table-column>
                            <el-table-column label="创建时间" prop="createTime"></el-table-column>
                            <el-table-column label="状态" prop="status">
                                <template slot-scope="scope">
                                    <div class="flx-row">
                                        <el-switch

                                                v-model="scope.row.status"
                                                :active-value="1"
                                                :inactive-value="0"
                                                @change="toggle(scope.row)"
                                        ></el-switch>
                                        <span class="ml-5">{{scope.row.status?'上架':'下架'}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="140">
                                <template slot-scope="scope">
                                    <div class="flx-row ali-c">
                                        <el-link
                                                type="primary"
                                                :underline="false"
                                                @click="handleEdit(scope.row.id)"
                                                class="mr-10"
                                        >
                                            <i class="el-icon-edit-outline"></i>
                                            编辑
                                        </el-link>
                                        <el-link type="primary" :underline="false" @click="handleDel(scope.row)">
                                            <i class="el-icon-delete"></i>
                                            删除
                                        </el-link>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <Pagination
                                :total="total"
                                :pageNum="pageNum"
                                :pageSize="pageSize"
                                :pageSizes="pageSizes"
                                @syncPageData="syncPageData"
                        />
                    </div>
                </div>
            </el-col>
        </el-row>
        <!-- <GoodsDialog ref="GoodsDialog" :tableHeader="tableHeader" :tabList="tabList" :hasTab="true"></GoodsDialog> -->
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import GoodsDialog from "@/components/GoodsDialog";
    import { getStoreList } from "@/api/common";
    import {
        getActivityList,
        upAndDownActivity,
        delActivty
    } from "@/api/market/activity";

    export default {
        name: "section_list",
        components: {
            Pagination,
            GoodsDialog
        },
        data() {
            return {
                goodsUrl: "/goods-service/goods/info/list",
                id: 1,
                // 商品列表表头
                tableHeader: [
                    {
                        value: "商品条形码",
                        key: "barCode"
                    },
                    {
                        value: "活动名称",
                        key: "activityName"
                    },
                    {
                        value: "一级类目",
                        key: "firstClassify"
                    },
                    {
                        value: "二级类目",
                        key: "secondClassify"
                    },
                    {
                        value: "实价",
                        key: "price"
                    },
                    {
                        value: "库存",
                        key: "stock"
                    }
                ],
                activePart: "",
                // 顶部搜索栏数据
                searchObj: {
                    barCode: "",
                    goodsName: "",
                    firstClassify: "",
                    secondClassify: "",
                    goodsStatus: "",
                    completeInfo: ""
                },
                // 一级分类下拉选项
                firstClassifyOptions: [
                    { id: 0, name: "一级分类1" },
                    { id: 1, name: "一级分类2" },
                    { id: 2, name: "一级分类3" }
                ],
                // 二级分类下拉选项
                secondClassifyOptions: [
                    { id: 0, name: "二级分类1" },
                    { id: 1, name: "二级分类2" },
                    { id: 2, name: "二级分类3" }
                ],
                // 商品状态下拉选项
                goodsStatusOptions: [
                    { id: 0, name: "下架" },
                    { id: 1, name: "上架" }
                ],
                // 是否完善信息下拉选项
                completeInfoOptions: [
                    { id: 0, name: "是" },
                    { id: 1, name: "否" }
                ],

                // 拼团表格数据内容
                tableData: [],
                total: 0,
                pageNum: 1,
                pageSize: 10,
                pageSizes: [10, 20, 30, 50, 100],
                shopList: [],
                upFlag: true,
                tabList: [
                    {
                        label: "商超商品",
                        name: "1",
                        url: "/goods-service/goods/info/list",
                        method: "post",
                        key: "goodsId"
                    },
                    {
                        label: "生鲜商品",
                        name: "2",
                        url: "http://www.tourask.com/mock/152/api/goodsList",
                        method: "get",
                        key: "id"
                    }
                ],
                storeName: "",
                storeFlag: false
            };
        },
        mounted() {
            if (this.$store.getters.storeId) {
                this.activePart = this.$store.getters.storeId;
                this.storeFlag = true;
            }
            this.getStoreList(); //查门店列表
        },
        watch: {
            activePart(val) {
                // this.search();
            }
        },
        methods: {
            //获取门店列表
            getStoreList() {
                getStoreList()
                    .then(res => {
                        console.log("门店列表 ",res.data.body.list)
                        this.shopList = res.data.body.list;
                        this.activePart = res.data.body.list.length && String(res.data.body.list[0].id) ;
                        console.log( this.activePart)
                        this.getGoodsList();
                    })
                    .catch(err => {
                        // console.log(err);
                    });
            },
            // 添加
            handleAdd() {
                let index = this.shopList.findIndex(item => {
                    return item.id == this.activePart;
                });
                this.$router.push({
                    name: "Section_goods_new",
                    query: {
                        storeId: this.activePart,
                        storeName: this.shopList[index].storeName
                    }
                });
            },
            //   删除
            handleDel(row) {
                if (row.status == 1) {
                    this.$message.warning("请先将活动下架之后再删除");
                    return;
                }
                this.isConfirm(
                    this,
                    () => {
                        delActivty({ id: row.id }).then(res => {
                            this.getStoreList();
                        });
                    },
                    "确定要删除吗？ 删除之后活动恢复原价售卖"
                );
            },
            // 查看商品
            handleShow() {
                this.$refs.GoodsDialog.showGoods = true;
                this.$refs.GoodsDialog.getGoods();
            },
            // 编辑
            handleEdit(id) {
                let index = this.shopList.findIndex(item => {
                    return item.id == this.activePart;
                });
                this.$router.push({
                    name: "Section_goods_new",
                    query: {
                        id,
                        storeId: this.activePart,
                        storeName: this.shopList[index].storeName
                    }
                });
            },
            // 上下架验证
            toggle(row) {
                const params = {
                    id: row.id,
                    status: row.status
                };
                upAndDownActivity(params).then(res => {});
            },
            // 上下架请求
            toggleReq() {
                request({
                    url,
                    method: "put"
                }).then(res => {
                    this.getGoodsList();
                });
            },
            // 切换tab
            changeTab(tab, event) {
                let id = tab.name;
                this.storeName = tab.storeName;
                this.getGoodsList();
            },
            //数据初始化
            getGoodsList() {
                let params = {
                    storeId: this.activePart,
                    status: this.searchObj.goodsStatus,
                    activityName: this.searchObj.goodsName,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    section: 1
                };
                console.log(params)

                getActivityList(params).then(res => {
                    console.log(res.data.body)
                    let data = res.data.body;
                    this.total = data.total;
                    this.tableData = data.list;
                });
            },
            // 搜索
            search() {
                this.pageNum = 1;
                this.getGoodsList();
            },
            // 重置搜索关键词
            reset() {
                for (let key in this.searchObj) {
                    this.searchObj[key] = "";
                }
                this.search();
            },
            // 页面列表 pageNum pageSize获取
            syncPageData(data) {
                // console.log(data);
                this.pageNum = data.num;
                this.pageSize = data.size;
                this.getGoodsList();
            }
        },
        filters: {
            statusFilter(val) {
                switch (val) {
                    case 0:
                        return "等待中";
                    case 1:
                        return "进行中";
                    case 2:
                        return "已结束";
                    default:
                        return "状态未知";
                }
            }
        }
    };
</script>
<style lang="scss" scoped>
    .member-table {
        .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
    }
    .el-avatar /deep/ img {
        width: 100%;
    }
</style>
